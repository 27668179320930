import React from "react"
import './index.css'
import styled from 'styled-components';
import background from '../images/yellowstone.jpg'

const Container = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: rgba(0,0,0, .6);


`

const BackDiv = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;

  width: 100vw;
  height: 100vh;

  background-image: url("https://upload.wikimedia.org/wikipedia/commons/c/c9/YellowstonefallJUN05.JPG");
  background-repeat: no-repeat;
  background-size: cover;

`
const FormDiv = styled.div`
  width: 75%;
  height: 100%;

  display: flex;
  flex-direction: column;

  justify-content:center;

  padding-left: 25px;
  

`
const CopyText = styled.h1`
  margin: 0;
  padding:0;

  color: white;

  font-size: 25px;

  padding-bottom: 10px;

`

const FieldDiv = styled.div`
`

const EmailInput = styled.input`
  margin-right: 20px;

`
export default function Home() {
  return (
    <Container>
      <BackDiv />
      <FormDiv>
        <CopyText>
          Looking for a campsite?
        </CopyText>
        <CopyText>
          Sign up to recieve a notification when NearbyCamping is available!
        </CopyText>
        <form name="Email Form" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="Email Form" />

          <FieldDiv>
            <EmailInput type="email" name="email" placeholder="Your Email" />
            <button type="submit">Submit</button>

          </FieldDiv>

        </form>


      </FormDiv>
    </Container>
  )
}
